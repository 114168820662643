var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.form.receiveItems,
          columns: _vm.columns,
          size: "small",
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          },
          pagination: {
            showSizeChanger: true,
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          scroll: { x: "calc(100% + 750px)" }
        },
        scopedSlots: _vm._u([
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "title",
            fn: function() {
              return [
                _c("a-button", {
                  attrs: {
                    icon: "delete",
                    type: "danger",
                    disabled: !_vm.form.purchaseOrderId
                  },
                  on: { click: _vm.handleDeleteRow }
                })
              ]
            },
            proxy: true
          },
          {
            key: "trackAsAsset",
            fn: function(text, row) {
              return [
                _c("a-switch", {
                  attrs: { disabled: !!_vm.store.detail.status },
                  model: {
                    value: row.trackAsAsset,
                    callback: function($$v) {
                      _vm.$set(row, "trackAsAsset", $$v)
                    },
                    expression: "row.trackAsAsset"
                  }
                })
              ]
            }
          },
          {
            key: "receiptQty",
            fn: function(text, row) {
              return [
                _c("a-input-number", {
                  staticClass: "w-100",
                  attrs: {
                    min: 0,
                    parser: _vm.reverseFormatNumber,
                    formatter: _vm.formatterNumber,
                    precision: _vm.storeBaseDecimalPlace,
                    disabled: !!_vm.store.detail.status
                  },
                  on: { change: _vm.calculateLines },
                  model: {
                    value: row.qty,
                    callback: function($$v) {
                      _vm.$set(row, "qty", _vm._n($$v))
                    },
                    expression: "row.qty"
                  }
                })
              ]
            }
          },
          {
            key: "unitPrice",
            fn: function(text, row) {
              return [
                _c("a-input-number", {
                  staticClass: "w-100",
                  attrs: {
                    min: 0,
                    parser: _vm.reverseFormatNumber,
                    formatter: _vm.formatterNumber,
                    precision: _vm.storeBaseDecimalPlace
                  },
                  on: { change: _vm.calculateLines },
                  model: {
                    value: row.price,
                    callback: function($$v) {
                      _vm.$set(row, "price", _vm._n($$v))
                    },
                    expression: "row.price"
                  }
                })
              ]
            }
          },
          {
            key: "discount",
            fn: function(text, row) {
              return [
                _c("a-input-number", {
                  staticClass: "w-100",
                  attrs: {
                    min: 0,
                    parser: _vm.reverseFormatNumber,
                    formatter: _vm.formatterNumber,
                    precision: _vm.storeBaseDecimalPlace
                  },
                  on: { change: _vm.calculateLines },
                  model: {
                    value: row.discountValue,
                    callback: function($$v) {
                      _vm.$set(row, "discountValue", _vm._n($$v))
                    },
                    expression: "row.discountValue"
                  }
                })
              ]
            }
          },
          {
            key: "receiptLocation",
            fn: function(text, row) {
              return [
                _c("SelectRack", {
                  staticClass: "w-100",
                  attrs: {
                    "branch-id": _vm.form.branchWarehouseId,
                    disabled: !!_vm.store.detail.status
                  },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeRack($event, row)
                    }
                  },
                  model: {
                    value: row.locationReceived,
                    callback: function($$v) {
                      _vm.$set(row, "locationReceived", $$v)
                    },
                    expression: "row.locationReceived"
                  }
                })
              ]
            }
          },
          {
            key: "notes",
            fn: function(text, row) {
              return [
                _c(
                  "a-badge",
                  { attrs: { count: row.itemAssets.length } },
                  [
                    _c("a-button", {
                      attrs: { icon: "bars" },
                      on: {
                        click: function($event) {
                          return _vm.viewItemAssets(row)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("ModalAssetLine", {
        attrs: { visible: _vm.store.modalState.visible },
        on: { close: _vm.handleCloseModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }